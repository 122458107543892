import { showSaveFilePicker } from 'native-file-system-adapter'
import { useFetch } from '#imports'

export const useNativeFileSystemAdapter = () => {
  interface Type {
    accept: Record<string, string[]>
  }

  // save file from url
  async function saveFileFromUrl(blobUrl: string, filename: string, types: Type[]) {
    if (navigator.serviceWorker) {
      try {
        navigator.serviceWorker.register('/scripts/useNativeFileSystemAdapterServiceWorker.js', {
          // type: 'module',
          // scope: './'
        })
        // console.info('service worker registered: useNativeFileSystemAdapterServiceWorker.js')
      } catch (error: unknown) {
        console.info((error as Error).message)
      }
    }

    const { data } = await useFetch<Blob>(blobUrl, { retry: 2, responseType: 'blob' })
    const blob = data.value
    if (!blob) throw new Error('no blob')
    let fileHandle
    try {
      fileHandle = await showSaveFilePicker({
        _preferPolyfill: false,
        suggestedName: filename,
        types,
        excludeAcceptAllOption: false, // default
      })
    } catch (error: unknown) {
      console.info('showSaveFilePicker dialog aborted by user')
    }
    if (fileHandle) {
      const writer = await fileHandle.createWritable()
      await writer.write(blob)
      await writer.close()
    }
  }

  // save file from blob
  async function saveFileFromBlob(blob: Blob, filename: string, types: Type[]) {
    if (navigator.serviceWorker) {
      try {
        navigator.serviceWorker.register('/scripts/useNativeFileSystemAdapterServiceWorker.js', {
          // type: 'module',
          // scope: './'
        })
        // console.info('service worker registered: useNativeFileSystemAdapterServiceWorker.js')
      } catch (error: unknown) {
        console.info((error as Error).message)
      }
    }

    let fileHandle
    try {
      fileHandle = await showSaveFilePicker({
        _preferPolyfill: false,
        suggestedName: filename,
        types,
        excludeAcceptAllOption: false, // default
      })
    } catch (error: unknown) {
      console.info('showSaveFilePicker dialog aborted by user')
    }
    if (fileHandle) {
      const writer = await fileHandle.createWritable()
      await writer.write(blob)
      await writer.close()
    }
  }

  return {
    saveFileFromUrl,
    saveFileFromBlob,
  }
}
