<script setup lang="ts">
import { useDisplay } from 'vuetify'
import { strLimitLength } from 'dvn-js-utils'

const props = defineProps<{
  modelValue: boolean
}>()

const emit = defineEmits(['update:modelValue'])
const isVisible = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit('update:modelValue', v)
  },
})

const { mobile } = useDisplay()
const audioPlayer = useAudioPlayer()
const containerWidth = 450
const containerWidthMobile = 350

async function close() {
  isVisible.value = false
}
</script>

<template>
  <VOverlay
    v-model="isVisible"
    location-strategy="connected"
    :scrim="false"
    scroll-strategy="none"
    :width="mobile ? containerWidthMobile : containerWidth"
    persistent
    transition="slide-y-reverse-transition"
    class="align-end justify-center"
  >
    <VCard class="elevation-16 playerContainer">
      <VToolbar color="primary" height="40">
        <div v-if="audioPlayer.audioPlayerData" :class="{ mobileText: mobile }">
          <span
            :class="{ mobileTitle: mobile }"
            style="margin-left: 8px"
            :style="mobile ? {} : { fontWeight: '500' }"
          >
            {{
              strLimitLength(
                audioPlayer.audioPlayerData.name ? audioPlayer.audioPlayerData.name : '',
                30,
              )
            }}
          </span>
          <span v-if="audioPlayer.audioPlayerData.demos && audioPlayer.playingIndex && !mobile">
            {{ ` : demo ${audioPlayer.playingIndex}/${audioPlayer.audioPlayerData?.demos.length}` }}
          </span>
          <span v-if="audioPlayer.audioPlayerData.demos && audioPlayer.playingIndex && mobile">
            {{ ` : ${audioPlayer.playingIndex}/${audioPlayer.audioPlayerData.demos.length}` }}
          </span>
        </div>
        <VSpacer />
        <VBtn color="surface" size="40" @click="close">
          <VIcon icon="mdi-close" />
        </VBtn>
      </VToolbar>
      <VCardText :class="{ mobileText: mobile }" style="padding: 0px">
        <CatalogMediaAudioPlayerWaveform />
      </VCardText>
    </VCard>
  </VOverlay>
</template>

<style lang="css" scoped>
.mobileText {
  font-size: 0.85em;
}
.mobileTitle {
  font-weight: 500;
}
.playerContainer {
  border-radius: 8px;
  border: 2px solid rgba(var(--v-theme-primary), 0.7);
}
</style>
